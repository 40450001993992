
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({
  components: {}
})
export default class Contact extends Vue {
  // 是否展示二维码
  @Prop({ default: true })
  private qrcode!: any
  // 是否展示微信公众号二维码
  @Prop({ default: true })
  private isWx!: any
  // 是否展示医生端
  @Prop({ default: true })
  private isDoctor!: any
}
