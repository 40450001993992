
import { Vue, Component } from 'vue-property-decorator'
import Swiper from 'swiper'
import contact from '@/components/contact.vue'

@Component({
  components: {
    contact
  }
})
export default class Consult extends Vue {
  private mounted () {
    const mySwiper = new Swiper('.swiper-container', {
      // loop: true,
      autoplay: false,
      direction: 'vertical',
      mousewheel: {
        sensitivity: 11,
        forceToAxis: true,
        invert: true
        // eventsTarged: 'body' // 鼠标在页面中任意地方都可控制swiper
      }, // 开启鼠标滚轮控制Swiper切换。可设置鼠标选项，或true使用默认值。
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      observeParents: true // 修改swiper的父元素时，自动初始化swiper
    })
  }
}
